import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  	selector: 'img',
})
export class ImgDirective {
	@Input('type') type: string = 'default';
	private el: HTMLElement;
	private isApplied: boolean = false;

	// public correct_url(value) {

	// 	if (environment.deployUrl != "" && value != undefined && value != null && value != "") {
	// 		if (value.startsWith("assets")) {
	// 			return environment.deployUrl + "/" + value;
	// 		} else if (value.startsWith("/assets")) {
	// 			return "/" + environment.deployUrl + "/" + value;
	// 		} else {
	// 			return value;
	// 		}
	// 	} else {
	// 		return value;
	// 	}
	// }

	constructor(el: ElementRef) {
		this.el = el.nativeElement;

		let src = this.el.getAttribute('src');
		//src = this.correct_url(src);

		//this.el.style.opacity = "0";

		this.el.setAttribute('src', src);
		//this.el.setAttribute('default-src', this.defaultImg());

		this.el.addEventListener('error', this.onError.bind(this));
		this.el.addEventListener('load', this.onLoad.bind(this));
	}

	public defaultImg() {
		if (this.type == 'user') {
			return environment.deployUrl + '/assets/images/user.png';
		} else if (this.type == 'no-image') {
			return environment.deployUrl + '/assets/images/no-image.svg';
		} else {
			return environment.deployUrl + '/assets/images/default.svg';
		}
	}



	private onError() {
		this.removeErrorEvent();
		
		//this.el.style.opacity = "1";
		if (!this.isApplied) {
			this.isApplied = true;
			let src = this.el.getAttribute('src');
			this.el.setAttribute('src', this.defaultImg());
			this.el.setAttribute('data-original-src', src);

			let classList: any = this.el.classList;
			this.el.setAttribute('class', classList.value + ' default-image default-image-' + this.type);
		}
		this.removeOnLoadEvent();
	}

	private onLoad() {
		//this.el.style.opacity = "1";
	}

	private removeErrorEvent() {
		this.el.removeEventListener('error', this.onError);
	}

	private removeOnLoadEvent() {
		this.el.removeEventListener('load', this.onLoad);
	}

	ngOnDestroy() {
		this.removeErrorEvent();
		this.removeOnLoadEvent();
	}

}
