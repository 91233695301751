import { ElementRef } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

export interface IValidation {
    getMessage(): string;
    value: any
    stop: any
    isValid(...params: any): boolean
}

export class Validation {
    stop: boolean = false;
    ngForm: NgForm;
    name: any = "This field";
    value: any = "";
    input_type: any = 'text';
   
    private _ngModel: NgModel;
    public get ngModel(): NgModel {
        return this._ngModel;
    }
    public set ngModel(value: NgModel) {
        this._ngModel = value;

        this.value = value.value || "";
        if (this.$el){
            this.name = this.formatName(this.$el.nativeElement.name || value.name);
        }
    }

    private _$el: ElementRef; 
    public get $el(): ElementRef {
        return this._$el;
    }
    public set $el(value: ElementRef) {
        this._$el = value;
        this.input_type = value.nativeElement.getAttribute('type');
    }

    constructor(input?: any) {
        if (input) {
            Object.assign(this, input);
        }
    }

    formatName(str) {
        str = str.match(/[a-z]+/gi)
            // .map(function (word) {
            //     return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
            // })
            .join(' ')

        return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
    }
}