import { Component, OnInit, ElementRef, Input, ContentChildren, QueryList, forwardRef, OnDestroy, Optional, Host, SkipSelf, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, NG_VALIDATORS, NgModel, ControlContainer, NgForm, NgControl, FormControl } from '@angular/forms';
import { FieldInputDirective } from '../field-input.directive';
import * as Validations from '../../validation';
import { IValidation } from '../../validation/validation';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormFieldComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormFieldComponent),
            multi: true,
        }
    ]
})
export class FormFieldComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

    errorMessage: any = [];
    subscriptions: Subscription[] = [];
    submitted: boolean = false;
    parseError: any = false;
    validations_array: any = [];
    errors: any = {};
    control: ElementRef;
    controls: ElementRef[];
    model: NgModel;

    @ViewChildren(FieldInputDirective) field: QueryList<FieldInputDirective>;


    private _validation: string = "";
    private _name: any;
    private _touched: boolean = false;
    private _isValid: boolean = false;

    constructor(
        @Optional() @Host() @SkipSelf()
        private ngForm: NgForm
    ) {

        this.ngForm.ngSubmit.subscribe(() => {
            this.submitted = true;
            this.setIsInValid();
        })
    }

    onChange: any = () => { }
    onTouch: any = () => { }
    onValidatorChange: any = () => { }

    public get isValid(): boolean {
        return this._isValid;
    }
    public set isValid(value: boolean) {
        this._isValid = value;
        this.setIsInValid()
    }

    public get touched(): boolean {
        return this._touched;
    }
    public set touched(value: boolean) {
        this._touched = value;
        this.setIsInValid()
    }

    setIsInValid() {
        if ((this.touched || this.submitted) && this.field && this.field.last) {
            this.field.last.isValid = this.isValid;
        }
    }

    @Input('validation')
    public get validation(): string {
        return this._validation;
    }
    public set validation(value: string) {
        this._validation = value;

        this.validations_array = value.split('|').map((item) => {
            let rule_parts: string[] = item.split(':');
            let params = [];
            let name = rule_parts[0];

            if (rule_parts.length > 1) {
                params = rule_parts[1].split(',').map((item) => {
                    return item.trim();
                });
            }

            return { name, params }
        });
    }

    @Input('name')
    public get name(): any {
        return this._name || "This field";
    }
    public set name(value: any) {
        this._name = value;
    }

    _value = ""
    get value() {
        return this._value;
    }
    set value(val) {
        this._value = val
        this.onChange(val);
    }


    writeValue(value: any) {
        this.value = value;
        this.field.forEach(field => {
            field.value = value ? value : null;
        });
    }

    registerOnChange(fn: any) {
        this.onChange = fn
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn
    }

    registerOnValidatorChange(fn: any) {
        this.onValidatorChange = fn
    }

    validate(c: FormControl) {
        let field_is_valid: boolean = true;

        this.errorMessage = [];
        //if (this.field.length > 0) {
        for (let index = 0; index < this.validations_array.length; index++) {

            const element = this.validations_array[index];
            const validation_name = this.camelCase(element.name);
            if (Validations[validation_name]) {
                const obj: IValidation = new Validations[validation_name]({
                    //  $el: this.field.last.el,
                    ngForm: this.ngForm,
                    ngModel: this
                })

                const is_valid = obj.isValid(...element.params);
                this.validations_array[index].is_valid = is_valid;

                if (!is_valid) {
                    field_is_valid = is_valid;
                    this.errorMessage.push(obj.getMessage());
                    this.errors[validation_name] = obj.getMessage();
                    if (obj.stop) {
                        break;
                    }
                }
            } else {
                throw `validation not found ${validation_name}`;
            }
        }
        //}



        if (!field_is_valid) {
            this.isValid = false;
        } else {
            this.errors = {};
            this.isValid = true;
        }

        let resp = this.isValid ? null : this.errors;
        c.setErrors(resp);

        return resp;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            for (let index = 0; index < this.subscriptions.length; index++) {
                this.subscriptions[index].unsubscribe();
            }
        }
    }

    ngAfterViewInit() {

        if (this.field.last) {
            this.field.forEach((item) => {
                this.subscriptions.push(item.ngModelChange.subscribe((value: any) => {
                    this.value = value;
                    this.touched = true;
                }));
            })
        }
    }

    // onControlChange($event: any) {
    //   this.value = $event.target.value
    // }
    camelCase(str) {
        return str.match(/[a-z]+/gi)
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
            })
            .join('')
    }
}
