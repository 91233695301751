import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition, group } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'http-message',
    template: `
        <div class="alert toggle-alert alert-{{_type}}" [ngClass]="{active : showMessage}">
            <div class="row">
                <div class="col">
                    <div *ngFor="let error of errorMessage">{{ error }} </div>
                </div>
                <div class="col-auto">
                    <span aria-hidden="true"  (click)="closeMessage()">×</span>
                </div>
            </div>
		</div>`,
    styles: ['.alert{display:none} .alert.active{ display:block } .material-icons{vertical-align: text-bottom; font-size: 1em;}'],

})
export class HttpMessage {
    errorMessage: any = [];
    showMessage: boolean = false;
    _type: any = "danger";


    @Input('type') set type(value) {
        if (value == 'error') {
            this._type = 'danger';
        } else {
            this._type = value;
        }
    };

    @Input('data') set data(response: any) {
        this.errorMessage = [];
        if (response){
            if (typeof response == 'string') {
                this.showMessage = true;
                this.errorMessage.push(response);
            } else  {
                this.showMessage = true;
                if (response.message != undefined) {
                    this.errorMessage.push(response.message);
                } else if (response.messages != undefined) {
                    let all_errors: any = response.messages;
                    Object.keys(all_errors).map((key) => {
                        for (let er of all_errors[key]) {
                            this.errorMessage.push(er);
                        }
                    });
                } else if (response.message != undefined || response.message != null) {
                    this.errorMessage.push(response.message);
                }
            }
        }else{
            this.showMessage = false;
            this.errorMessage = [];
        }
    };

    constructor() {

    }

    closeMessage() {
        this.showMessage = false;
        setTimeout(() => {
            this.errorMessage = [];
        }, 500);
    }
}
