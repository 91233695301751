import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PromptComponent } from './prompt/prompt.component';
import { CustomComponent } from './custom/custom.component';

@Injectable()
export class DialogService {
	public dialogRef:any
  	constructor(private dialog:MatDialog) { }


  	dialogOpen(component:any, option:any = {}){
	  	let new_options = Object.assign({
			panelClass : "dialog-md modal-md",
		  	data: { }
	  	}, option);
	  	this.dialogRef = this.dialog.open(component, new_options);
  	}
  	dialogClose(): void {
	    this.dialogRef.close();
	}
}
