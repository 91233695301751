import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavItem } from './nav-item';
import { Router } from '@angular/router';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavService } from '../../services/nav.service';


@Component({
	selector: 'app-menu-list-item',
	templateUrl: './menu-list-item.component.html',
	styleUrls: ['./menu-list-item.component.scss'],
	animations: [
		trigger('indicatorRotate', [
			state('collapsed', style({ transform: 'rotate(0deg)' })),
			state('expanded', style({ transform: 'rotate(180deg)' })),
			transition('expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
			),
		])
	]
})
export class MenuListItemComponent implements OnInit {
	expanded = {};
	@HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
	@Input() items: NavItem[];
	@Input() depth: number;

	constructor(
		public navService: NavService,
		public router: Router
	) {
		if (this.depth === undefined) {
			this.depth = 0;
		}

	}

	ngOnInit() {
		this.navService.currentUrl.subscribe((url: string) => {
		
			this.items.forEach((item: NavItem, index) => {
				let expands: boolean = false;
				if (item.route && url) {
					if (url.indexOf(`${item.route}`) === 0){
						expands = true
					}
				}
				
				if (item.children && item.children.length > 0){
					item.children.forEach((child_item: NavItem) => {
						if(url.indexOf(`${child_item.route}`) === 0){
							expands = true
						}
					})
				}

				if (expands){
					this.expanded[this.depth + "_" + index] = true;
					this.ariaExpanded = this.expanded[this.depth + "_" + index];
				}else{
					this.expanded[this.depth + "_" + index] = false;
				}
			});

		});
	}

	childRouteActive(item: NavItem) {
		let is_active: boolean = false;
		if (item.children.length > 0) {
			item.children.forEach((item: NavItem) => {
				if (!is_active) {
					is_active = this.router.isActive(item.route, true);
				}
			});
		}
		return is_active;
	}

	onItemSelected(item: NavItem, index: any) {
		if (!item.children || !item.children.length) {
			this.router.navigate([item.route]);
			//this.navService.closeNav();
		}
		if (item.children && item.children.length) {
			this.expanded[this.depth + "_" + index] = !this.expanded[this.depth + "_" + index];
		}
	}
}
