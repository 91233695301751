export const environment = {
  production: true,
  deployUrl: '',
  apiEndPoint: 'https://ecdis-staging-api.mcd-uk.com',
  baseUrl: '',
  apiUrl: 'https://ecdis-staging-api.mcd-uk.com/api',
  hmr: false,
  firebaseConfig: {
    apiKey: "AIzaSyBhe0fmAL4bKjpJY_x0JXmzE4bcDRIcEPQ",
    authDomain: "ecdis-3da51.firebaseapp.com",
    projectId: "ecdis-3da51",
    storageBucket: "ecdis-3da51.appspot.com",
    messagingSenderId: "93755849616",
    appId: "1:93755849616:web:070f4e1d98abafbe6a1115",
    measurementId: "G-WLHC31KTJN"
  },
};
