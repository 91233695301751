import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  	constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  	ngOnInit() {
  	}

  	callback(confirm){
  		this.data.callback(confirm);
  	}

}
