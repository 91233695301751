import { Component, OnInit, forwardRef, TemplateRef, ContentChildren, QueryList, Output, EventEmitter, AfterContentInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RepeaterFieldDirective } from '../dirictives/repeater-field.directive';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
	selector: 'repeater',
	templateUrl: './repeater.component.html',
	styleUrls: ['./repeater.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RepeaterComponent),
			multi: true
		}
	],
})
export class RepeaterComponent implements OnInit, AfterContentInit {
	
	@ContentChildren(TemplateRef) templates: QueryList<any>;
	@ContentChildren(RepeaterFieldDirective) fields: QueryList<RepeaterFieldDirective>;

	@Output() onAddNew:EventEmitter<any> = new EventEmitter();
	@Output() onRemove:EventEmitter<any> = new EventEmitter();
	@Output() onDropped:EventEmitter<any> = new EventEmitter();

	private _defaultRow: number = 1;
	@Input()
	public get defaultRow(): number {
		return this._defaultRow;
	}
	public set defaultRow(value: number) {
		this._defaultRow = value;
	}


	private _addButtonText: string = "Add New";
	@Input()
	public get addButtonText(): string {
		return this._addButtonText;
	}
	public set addButtonText(value: string) {
		this._addButtonText = value;
	}
	
	public field:any = {};
	public fieldTemplate: TemplateRef<any>;

	private _repeater_value: any;
	public get repeater_value() {
		return this._repeater_value;
	}
	public set repeater_value(value: any) {
		let data_type = typeof value;
		
		if(data_type == 'string'){
			try {
				value = JSON.parse(value);
			}catch (e) {
				value = [];
			}
		}else if(!value){
			value = [];
		}
		this._repeater_value = value;
		this.propagateChange(this._repeater_value);
		if(this._repeater_value.length == 0){
			for (let i = 0; i < this.defaultRow; i++) {
				this.addNewField();
			}
		}
	}

	constructor() {

	}

	ngOnInit() {

	}

	ngAfterContentInit(): void {
		this.fieldTemplate = this.templates.first
		let subscriber:Subscription;
		subscriber = this.fields.changes.subscribe(()=>{
			if(this.fields.length > 0 && !subscriber.closed){
				subscriber.unsubscribe();
			}
			this.fields.forEach((item) => {
				this.field[item.field_name] = "";
			});
		});
	}

	addNewField() {
		let value:any = Object.assign({}, this.field);
		this.onAddNew.emit(value);
		this.repeater_value = this.repeater_value.concat([value]);
	}

	onDrop(event: CdkDragDrop<string[]>) {
		this.onDropped.emit(event)
	}
	removeField(field) {
		this.onRemove.emit(field);
		this.repeater_value.splice(this.repeater_value.indexOf(field), 1);
		this.repeater_value = this.repeater_value;
	}
	
	propagateChange:Function = (_: any) => {};

	writeValue(value: Array<any> = []) {
		this.repeater_value = value;
	}

	registerOnChange(fn:Function) {
		this.propagateChange = fn;
	}
	
	registerOnTouched() {}


}
