import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import { DialogsModule } from './shared/modules/dialogs/dialogs.module';
import { ToastyModule } from 'ngx-toasty';
import { Services } from './shared/services';
export function tokenGetter() {
    return localStorage.getItem('admin-token');
}
import { MomentModule } from 'ngx-moment';

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpCalIInterceptor } from './shared/services/auth/http.interceptor';
import { AppStorage } from './shared/services/cookies/app-storage';
import { CookieStorage } from './shared/services/cookies/cookie.storage';
import { AngularFireAnalyticsModule, CONFIG, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
// import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
// import { AngularFireModule } from "@angular/fire/compat";
import { environment } from '../environments/environment';

// export function init_app(appSettingsService: AppInitService) {
//     return () => appSettingsService.init_app();
// }

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        DialogsModule.forRoot(),
        TranslateModule.forRoot(),
        ToastyModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: new Array(new RegExp('^null$'))
            }
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MomentModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        MatPaginatorModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCalIInterceptor,
            multi: true,
        },
        { provide: AppStorage, useClass: CookieStorage },
        // { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppInitService], multi: true },
        ScreenTrackingService,
        UserTrackingService,
        {
            provide: CONFIG, useValue: {
                // send_page_view: false,
                allow_ad_personalization_signals: false,
                anonymize_ip: true
            }
        },
        {
            provide: DEBUG_MODE, useValue: true
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
