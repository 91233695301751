import { AssetsPath } from './image';
import { WrapPTag } from "./wrap-p-tag";
import { FilterPipe } from './filter.pipe';
import { UpdateAnchor, SafeHtmlPipe, TruncatePipe, UcFirstPipe } from './text';
import { StrToDatePipe } from './str-to-date.pipe';
import { AssetsUrl } from './assets-url.pipe';
import { AutofocusDirective } from './autofocuss.pipe';
import { FilterParentPipe } from './filter-parent.pipe';

const Pipes = [
    AssetsPath,
    FilterPipe,
    WrapPTag,
    UpdateAnchor,
    TruncatePipe,
    StrToDatePipe,
    AssetsUrl,
    UcFirstPipe,
    AutofocusDirective,
    FilterParentPipe
];

export { Pipes };
