import { Directive, Input, HostListener, EventEmitter } from '@angular/core';

@Directive({
	selector: '[RepeaterField]'
})
export class RepeaterFieldDirective {

	@Input('RepeaterField') field_name: string;
	

	onChange:EventEmitter<any> = new EventEmitter();

	@HostListener('change',['$event'])
	@HostListener('input',['$event'])
	onInput(){
		this.onChange.emit();
	}

	constructor() { }

}
