export class DatatableRequest {
    page:number = 1;
    limit:number = 10;
    filter:any = {};
    order:any = "";
    order_by:any = "";
}

export interface DatatableInterface {
    request: DatatableRequest;
}
