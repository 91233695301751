import { Domain } from './domain';
import { IValidation, Validation } from './validation';

export class Email extends Validation implements IValidation {

    isValid() {
        let emailParts: string[] = this.value.toLowerCase().split('@');
        let EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return (EMAIL_REGEXP.test(this.value));
    }

    getMessage(): string {
        return `Please enter a valid email address.`;
    }
}