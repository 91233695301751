import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
	input:any;
  	
  	constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  	ngOnInit() {

  	}

}
