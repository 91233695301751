import { Component, OnInit, Input, ContentChildren, QueryList, ViewChild, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { ColumnComponent } from '../column/column.component';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { merge, Observable, Subject } from 'rxjs';
import { startWith, switchMap, map, debounceTime } from 'rxjs/operators';
import { NgModel } from '@angular/forms';


export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

export interface FilterColumn {
    name: string;
    type: string;
}

@Component({
    selector: 'datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit {

    resultsLength = 0;
    isLoadingResults = false;
    displayNoRecords = false;
    isRateLimitReached = false;

    _this: DatatableComponent;

    selection = new SelectionModel<PeriodicElement>(true, []);
    displayedColumns: string[] = [];
    dataSource: MatTableDataSource<any>;

    datatable_refresh: Subject<any> = new Subject();
    filter: Subject<any> = new Subject();
    filter_value: string;
    filter_in: Array<FilterColumn> = [];
    cols_data: any = {};

    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    )

    @ViewChild(MatPaginator, {static : true}) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    
    @ContentChildren(ColumnComponent) cols: QueryList<ColumnComponent>;

    @Output() bulkAction: EventEmitter<any[]> = new EventEmitter<any[]>();

    dataApi: Function | null;

    @Input() filterInput: NgModel 
       

    @Input() select: any = false;
    private _value: any[] | Observable<any[]> = [];
    noRecordsfound: boolean;
    @Input() get value(): any {
        return this._value;
    }
    set value(val: any) {
        if (val instanceof Function) {
            this.dataSource = new MatTableDataSource([]);
            this.dataApi = val;
        } else {
            this.dataApi = null
            this._value = val ? [...val] : [];
            this.dataSource = new MatTableDataSource(this._value);
            this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }
    }

    constructor() {
        this._this = this;
    }

    ngOnInit() {
        if (this.dataApi) {
            this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

            merge(this.sort.sortChange, this.datatable_refresh, this.paginator.page, this.filter.pipe(debounceTime(500)))
                .pipe(
                    startWith({}),
                    switchMap(() => {
                        this.isLoadingResults = true;
                        let request_data: any = {
                            page: this.paginator.pageIndex,
                            limit: this.paginator.pageSize || 20
                        }

                        if (this.sort.active) {   
                            const order_col = this.cols_data[this.sort.active];

                            request_data.order = {
                                direction: this.sort.direction ? this.sort.direction : 'asc',
                                name: order_col.column,
                                type: order_col.dataType
                            };
                            request_data.order = JSON.stringify(request_data.order);

                        }
                        if (this.filter_value) {
                            request_data.filter_in = this.filter_in;
                            request_data.filter_in = JSON.stringify(request_data.filter_in);
                            request_data.filter = this.filter_value;
                        }
                                                
                        return this.dataApi(request_data)
                    }),
                    map((data: any) => {
                        this.isLoadingResults = false;
                        this.isRateLimitReached = false;
                        this.resultsLength = data.meta.total;
                        return data.data;
                    }),
                    /* catchError(() => {
                        this.isLoadingResults = false;
                        this.isRateLimitReached = true;
                        return of([]);
                    }) */
                )
                .subscribe((data: any) => {
                    const mainDiv = document.getElementById('scrollTome');
                    if(mainDiv){
                        mainDiv.scrollTop = 0;
                    }
                    this.dataSource = data
                    //if (this.dataSource.filteredData && this.dataSource.filteredData.length == 0){
                        if(data.length == 0){
                            this.noRecordsfound = true;
                        }else{
                            this.noRecordsfound = false;
                        }
                   // }
                });
        }else{
            const mainDiv = document.getElementById('scrollTome');
            if(mainDiv){
                mainDiv.scrollTop = 0;
            }
            //if (this.dataSource.filteredData && this.dataSource.filteredData.length == 0) {
                if (this.value.length == 0) {
                    this.noRecordsfound = true;
                } else {
                    this.noRecordsfound = false;
                }
            //}
        }


        this.dataSource.filterPredicate = (data, filter: string): boolean => {
            const searchable = this.getSearchableColumns();
            for (const col of searchable) {
                if (data[col.field] != undefined && filter && data[col.field].toLowerCase().includes(filter.toLowerCase())) {
                    return true;
                }
            }
        };       
    }
    
    ngAfterViewInit() {
        this.filter_in = [];
        this.cols_data = {};

        this.cols.forEach((col) => {
            this.cols_data[col.field] = { field: col.field, dataType: col.dataType, header: col.header, column: col.column };
        });

        const searchable = this.getSearchableColumns();
        for (const col of searchable) {
            this.filter_in.push({ name: col.column, type: col.dataType });
        }

        if (this.filterInput){
            this.filterInput.valueChanges.pipe(debounceTime(500)).subscribe((data)=>{
                this.applyFilter(data);
            })
        }
        
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.value.length;
        return numSelected === numRows;
    }
    
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue ? filterValue.trim().toLowerCase() : "";
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
        this.filter_value = this.dataSource.filter
        this.filter.next(this.dataSource.filter);

        if (this.dataSource.filteredData && this.dataSource.filteredData.length == 0) {
            this.displayNoRecords = true;
        } else {
            this.displayNoRecords = false;
        }
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.value.forEach(row => this.selection.select(row));
    }


    getSearchableColumns(): Array<ColumnComponent> {
        return this.cols.filter(col => col.searchable);
    }

    refresh() {
        this.datatable_refresh.next({});
    }

    firstPage() {
        this.paginator.firstPage();
    }
}
