import { IValidation, Validation } from './validation';

export class Number extends Validation implements IValidation {
    attrs
    isValid(...attrs) {
        this.attrs = attrs;
        return !isNaN(parseInt(this.value));
    }

    getMessage(): string {
        return `Please enter numeric value.`;
    }
}
