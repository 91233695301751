import { NgModule, ModuleWithProviders } from '@angular/core';
import { MatDialogModule,  MatRadioModule, MatListModule,  MatButtonModule} from '@angular/material';
import { FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PromptComponent } from './prompt/prompt.component';
import { CustomComponent } from './custom/custom.component';

import { DialogService } from './dialog.service';

import { AlertDirective } from './directive/alert.directive';
import { ConfirmDirective } from './directive/confirm.directive';
import { PromptDirective } from './directive/prompt.directive';
import { CustomDirective } from './directive/custom.directive';


@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatRadioModule,
    MatListModule,
    MatButtonModule,
    FormsModule
  ],
  declarations: [
    AlertComponent, 
    ConfirmComponent, 
    PromptComponent, 
    CustomComponent, 

    AlertDirective, 
    ConfirmDirective, 
    PromptDirective, 
    CustomDirective
  ],
  exports: [
  	AlertDirective, 
  	ConfirmDirective, 
  	PromptDirective, 
  	CustomDirective
  ],
  entryComponents: [
    AlertComponent, 
    ConfirmComponent, 
    PromptComponent, 
    CustomComponent
  ],
  providers: []
})
export class DialogsModule { 
	static forRoot(): ModuleWithProviders {
	    return {
	      ngModule: DialogsModule,
	      providers: [DialogService]
	    };
	}

}
