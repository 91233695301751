import { Directive } from '@angular/core';

@Directive({
  	selector: '[appFieldLabel]'
})
export class FieldLabelDirective {

	constructor(

	) { }

}
