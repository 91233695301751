import { Domain } from './domain';
import { IValidation, Validation } from './validation';

export class Required extends Validation implements IValidation {

    stop: boolean = true;

    isValid() {
        
        switch (this.input_type) {
            case 'checkbox':
                return this.$el.nativeElement.checked;

            case 'radio':
                return !(!(this.value))
                // this.$el.nativeElement.form.querySelector('[name="' + this.$el.nativeElement.attr('name') + '"]').filter(':checked').length > 0;
            default:
                return (this.value + "").trim() !== '';
        }
    }

    getMessage(): string {
        return `${this.name} is required.`;
    }
}
