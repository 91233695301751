import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ToastData,
  ToastOptions,
  ToastyConfig,
  ToastyService,
} from 'ngx-toasty';

@Injectable()
export class Helper {
  constructor(
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private router: Router
  ) {
    this.toastyConfig.position = 'top-left';
  }

  apiUrl(path: any = '') {
    return 'api/' + path;
  }

  loading(status: boolean) {
    if (status) {
      // this.completeProgress();
      this.startProgress();
    } else {
      this.completeProgress();
    }
  }

  successMessage(data: any, message?: any) {
    if (data.message != undefined) {
      this.addToast(data.message, 'Success!', 'success');
    } else {
      this.addToast(message, 'Success!', 'success');
    }
  }

  errorMessage(error: any, default_message?: any) {
    if (error.message == 'No JWT present or has expired') {
      this.addToast('You are not login, Please login.', 'Oops!', 'error');
      this.router.navigate(['/login/shipping']);
      return;
    }
    if (error == '' || error == null) {
      this.addToast(default_message, 'Oops!', 'error');
      return;
    }
    if (error.error != undefined) {
      error = error.error;
    } else {
      error = error;
    }
    if (error.message == 'Token has expired') {
      this.addToast(error.message, 'Oops!', 'error');
      this.router.navigate(['/login/shipping']);
      return;
    }
    let all_messages: any = {};
    let message: any = '';

    if (error.messages != undefined) {
      if (this.isJsObject(error.messages)) {
        all_messages = error.messages;
        message = [];
        Object.keys(all_messages).map(key => {
          for (const er of all_messages[key]) {
            message.push(er);
          }
        });
        message = message.join('<br>');
      } else {
        message = error.messages;
      }
      switch (error.type) {
        case 'expired':
          this.addToast(message, 'Oops!', 'error');
          break;

        default:
          this.addToast(message, 'Oops!', 'error');
          break;
      }
    } else {
      if (error.message != undefined || error.message != null) {
        message = error.message;
        this.addToast(message, 'Oops!', 'error');
      } else if (error.error != undefined || error.error != null) {
        this.addToast(error.error, 'Oops!', 'error');
      } else {
        this.addToast(default_message, 'Oops!', 'error');
      }
    }

    if (error.code == 404) {
      // this.router.navigate(['/404']);
    }
  }

  objectToParams(object: any): string {
    return Object.keys(object)
      .map(key => {
        if (this.isJsObject(object[key])) {
          return this.subObjectToParams(encodeURIComponent(key), object[key]);
        } else {
          if (object[key] != null) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              object[key]
            )}`;
          } else {
            return `${encodeURIComponent(key)}=`;
          }
        }
      })
      .join('&');
  }

  subObjectToParams(key: any, object: any): string {
    return Object.keys(object)
      .map(childKey => {
        if (this.isJsObject(object[childKey])) {
          return this.subObjectToParams(
            `${key}[${encodeURIComponent(childKey)}]`,
            object[childKey]
          );
        } else {
          return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(
            object[childKey]
          )}`;
        }
      })
      .join('&');
  }

  isJsObject(object: any) {
    const type: string = typeof object;
    return (type == 'object' || type == 'Array') && type != null;
  }

  addToast(msg: any, title: any, type: any) {
    const toastOptions: ToastOptions = {
      title: title,
      msg: msg,
      showClose: true,
      timeout: 5000,
      theme: 'bootstrap',
      onAdd: (toast: ToastData) => {},
      onRemove: function(toast: ToastData) {},
    };
    switch (type) {
      case 'default':
        this.toastyService.default(toastOptions);
        break;
      case 'info':
        this.toastyService.info(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
      case 'wait':
        this.toastyService.wait(toastOptions);
        break;
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'warning':
        this.toastyService.warning(toastOptions);
        break;
    }
  }

  setProgres(status: any) {
    // this.slimLoader.progress = status;
  }
  startProgress() {
    // this.slimLoader.start();
  }
  completeProgress() {
    // this.slimLoader.complete();
  }
  stopProgress() {
    // this.slimLoader.stop();
  }
  resetProgress() {
    // this.slimLoader.reset();
  }
  incrementProgress() {
    // this.slimLoader.progress++;
  }
}
