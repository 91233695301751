import { Domain } from './domain';
import { IValidation, Validation } from './validation';

export class CreditCard extends Validation implements IValidation {
    attrs
    isValid(...attrs) {
        let value = this.value;
        if (/[^0-9 \-]+/.test(value)) {
            return false;
        }

        var nCheck = 0,
            nDigit = 0,
            bEven = false,
            n, cDigit;

        value = value.replace(/\D/g, "");

        // Basing min and max length on
        // https://dev.ean.com/general-info/valid-card-types/
        if (value.length < 13 || value.length > 19) {
            return false;
        }

        for (n = value.length - 1; n >= 0; n--) {
            cDigit = value.charAt(n);
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) {
                    nDigit -= 9;
                }
            }

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) === 0;
    }

    getMessage(): string {
        return `Please enter a valid credit card number.`;
    }
}