import { Domain } from './domain';
import { IValidation, Validation } from './validation';

export class Max extends Validation implements IValidation {
    attrs
    isValid(...attrs) {
        this.attrs = attrs;
        return this.value.length <= this.attrs[0];
    }

    getMessage(): string {
        return `Please enter no more than ${this.attrs[0]} characters.`;
    }
}