import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Components } from './components';
import { Pipes } from './pipes';
import { RouterModule } from '@angular/router';
import { Directives } from './diractive';
import { CustomModules } from './modules';
import { AuthGuard } from './services/auth/auth.guard';
import { MAT_DATE_LOCALE } from "@angular/material";
import { Helper } from './services/helper';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const Modules = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MomentModule,
  NgbModule
];


@NgModule({
  imports: [
    CommonModule,
    ...Modules,
    CustomModules
  ],
  declarations: [
    ...Components,
    ...Pipes,
    ...Directives,
  ],
  exports: [
    CustomModules,
    ...Modules,
    ...Pipes,
    ...Components,
    ...Directives
  ],
  entryComponents: [
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ 
        Helper,
        AuthGuard,
        { provide: MAT_DATE_LOCALE, useValue: 'en' },
      ]
    };
  }
}
