import { ImageTagComponent } from './image-tag/image-tag.component';
import { LoadingComponent } from './loading/loading.component';
import { ControlMessages } from './control-messages';
import { HttpMessage } from './http-message';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';

const Components = [
    ImageTagComponent,
    LoadingComponent,
    ControlMessages,
    HttpMessage,
    MenuListItemComponent,
];

export { Components };
