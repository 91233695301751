import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { apiUrl } from 'app/shared/utils';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  roleName: any;
  user: any;
  constructor(private router: Router, private authService: AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let return_data: any = await new Promise((resolve, reject) => {
      const token = this.authService.getToken();
      this.user = this.authService.getUser();
      if (this.user) {
        const loggedinuser = JSON.parse(this.user);
        this.roleName = loggedinuser.user.role.name;
      }

      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });

    if (this.roleName === 'super_admin' && state.url.startsWith('/dashboard')) {
      return return_data;
    } else if (
      this.roleName === 'content_admin' &&
      (state.url.startsWith('/dashboard') ||
        state.url.startsWith('/shipping-company') ||
        state.url.startsWith('/question') ||
        state.url.startsWith('/sub-question') ||
        state.url.startsWith('/shippinguser-list'))
    ) {
      return return_data;
    } else if (
      (this.roleName === 'shipping_admin' || this.roleName === 'super_shipping_admin') &&
      (state.url.startsWith('/dashboard') ||
        state.url.startsWith('/batches') ||
        state.url.startsWith('/users'))
    ) {
      return return_data;
    } else {
      if (this.roleName === 'content_admin') {
        console.log('here');

        localStorage.clear();
        this.router.navigate(['/login']);
      } else {
        localStorage.clear();
        // this.router.navigate(['/login/shipping']);
        window.location.href = apiUrl('auth/sso/login');
      }
    }
  }
}
