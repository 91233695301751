import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-image-tag',
  templateUrl: './image-tag.component.html',
  styleUrls: ['./image-tag.component.scss']
})
export class ImageTagComponent implements OnInit {
  public loading = true;
  public loadingImg: string;
  @Input() imageSize: string;
  private _imageData: any;

  @Input()
  public get imageData(): any {
    return this._imageData;
  }

  public set imageData(value: any) {
    this._imageData = value;
    if (this.imageData !== undefined && this.imageData !== null) {
 
      
      if (this.imageData.meta !== undefined) {
        if (this.imageData.meta._wp_attachment_metadata !== undefined) {
          
          const name = this.imageData.meta._wp_attachment_metadata.file;
          
          if (name != undefined){
            this.imgFolder += name.substring(0, name.lastIndexOf('/') + 1);
            if (this.title === '') {
              this.title = this.imageData.post_name;
            }
            if (this.updateSize() == "full" ) {
              this.imgFolder = this.imageData.guid || this.imageData.src;
              return;
            }
            if ((this.updateSize() !== undefined) && (this.updateSize() !== '') && (this.updateSize() !== null)) {

              if (this.imageData.meta._wp_attachment_metadata.sizes[this.updateSize()] === undefined) {
                const sizes = this.imageData.meta._wp_attachment_metadata.sizes;
                const ke = Object.keys(sizes)[0];
                if(sizes[ke] != undefined)
                this.imgFolder += sizes[ke].file;
              } else {
               
                this.imgFolder += this.imageData.meta._wp_attachment_metadata.sizes[this.updateSize()].file;
              }

            } else {
              this.imgFolder = this.imageData.src;
            }
          } else {
            this.imgFolder = 'assets/images/blog-placeholder.svg?v1';
          }
        } else {
          this.imgFolder = this.imageData.src;
        }
      } else {
        this.imgFolder = 'assets/images/blog-placeholder.svg?v1';
      }
    }else{
      this.imgFolder = 'assets/images/blog-placeholder.svg?v1';
    }
  }

  @Input() title: any;

  env: any;
  imgFolder: string;


  constructor(private renderer: Renderer2) {
    this.env = environment;
    this.imgFolder = this.env.wpPath + '/wp-content/uploads/';
  }

  ngOnInit() {
    this.loadingImg = 'assets/images/blog-placeholder.svg?v1';
  }

  private updateSize() {
    return this.imageSize;
  }

  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    if (this.renderer) {
      this.renderer.addClass(target, visible ? 'in-port' : 'out-port');
    }
  }

  onError() {
    this.imgFolder = 'assets/images/blog-placeholder.svg?v1';
    this.loading = false;
  }
  onLoad() {
    this.loading = false;
  }

}
