import { MaterialModule } from './material/material.module';
import { DatatableModule } from './datatable';
import { DialogsModule } from './dialogs/dialogs.module';
import { FormControlsModule } from './form-controls/form-controls.module';
import { RepeaterFieldModule } from './repeater-field/repeater-field.module';

export * from './page-header/page-header.module';
export * from './stat/stat.module';

const CustomModules = [
    MaterialModule,
    DatatableModule,
    DialogsModule,
    FormControlsModule,
    RepeaterFieldModule,
];


export { CustomModules };