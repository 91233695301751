import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './datatable/datatable.component';
import { ColumnComponent } from './column/column.component';
import { BodyTemplate } from "./column/body-template";
import { MatTableModule, MatPaginatorModule, MatCheckboxModule, MatProgressSpinnerModule, MatSortModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatIconModule, MatGridListModule } from '@angular/material';
import { DisplayedColumnsPipe } from './displayed-columns-pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatIconModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [DatatableComponent, ColumnComponent, BodyTemplate, DisplayedColumnsPipe],
  exports: [DatatableComponent, ColumnComponent, BodyTemplate]
})
export class DatatableModule { }
