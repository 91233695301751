import { environment } from 'environments/environment';

export function apiUrl(path?: string) {
  try {
    const newUrl = new URL(path);
    if (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') {
      return path;
    }
    return environment.apiUrl + '/' + path;
  } catch (err) {
    return environment.apiUrl + '/' + path;
  }
}
export function apiUlrValue(): string {
  return environment.apiEndPoint;
}

export function scrollToElement(
  item: any,
  duration: number = 750,
  container?: any,
) {
  if (typeof item === 'string') {
    item = document.querySelector(item);
  }
  if (item) {
    const itemPos = item.offsetTop;
    if (container) {
      if (typeof container === 'string') {
        container = document.querySelector(container);
      }
      scrollTo(container, itemPos, duration, true);
    } else {
      scrollTo(window.document, itemPos, duration);
    }
  }
}

export function scrollTo(
  element,
  to: number,
  duration,
  isContainer: boolean = false,
) {
  const increment = 20;
  let start,
    remaining,
    currentTime = 0,
    animateScroll;

  if (isContainer) {
    // for custom container element
    start = element.scrollTop;
  } else if (element.body.scrollTop > 0) {
    // for chrome
    start = element.body.scrollTop;
  } else if (element.documentElement.scrollTop > 0) {
    // for firefox
    start = element.documentElement.scrollTop;
  } else {
    start = 0;
  }

  remaining = to - start;

  animateScroll = () => {
    currentTime += increment;
    const val = easeInOut(currentTime, start, remaining, duration);
    if (isContainer) {
      element.scroll(0, val);
    } else {
      // to allow scroll function on different browsers both chrome and firefox
      top.window.scroll(0, val);
    }

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

export function easeInOut(
  currentTime: number,
  startTime: number,
  remainingTime: number,
  duration: number,
) {
  currentTime /= duration / 2;

  if (currentTime < 1) {
    return (remainingTime / 2) * currentTime * currentTime + startTime;
  }

  currentTime--;
  return (
    (-remainingTime / 2) * (currentTime * (currentTime - 2) - 1) + startTime
  );
}

export function objectToParams(object: any): string {
  return Object.keys(object)
    .map(key => {
      if (isJsObject(object[key])) {
        return subObjectToParams(encodeURIComponent(key), object[key]);
      } else {
        if (object[key] != null)
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            object[key],
          )}`;
        else return `${encodeURIComponent(key)}=`;
      }
    })
    .join('&');
}

export function subObjectToParams(key: any, object: any): string {
  return Object.keys(object)
    .map(childKey => {
      if (isJsObject(object[childKey]))
        return subObjectToParams(
          `${key}[${encodeURIComponent(childKey)}]`,
          object[childKey],
        );
      else
        return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(
          object[childKey],
        )}`;
    })
    .join('&');
}

export function isJsObject(object: any) {
  let type: string = typeof object;

  return (type == 'object' || type == 'Array') && object != null;
}

export function extToType(file_ext) {
  let type: string = '';
  switch (file_ext.toLowerCase()) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      type = 'image';
      break;

    case 'mpg':
    case 'flv':
    case 'avi':
    case '3gp':
    case 'mp4':
    case 'mov':
    case 'vob':
    case 'webm':
    case 'ogv':
      type = 'video';
      break;

    case 'pdf':
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
    case 'ppt':
    case 'pptx':
    case 'csv':
      type = 'file';
      break;

    case 'mp3':
    case 'm4a':
    case 'ac3':
    case 'mp4a':
    case 'mpga':
    case 'aac':
    case 'wav':
    case 'wma':
      type = 'audio';
      break;

    default:
      break;
  }

  return type;
}
