import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filterParent'
})
export class FilterParentPipe implements PipeTransform {

    transform(value: any, keyName: any, parentId: any, ): any {
        return _.filter(value, function (o) {
            return o[keyName] === +parentId;
        });
    }
}