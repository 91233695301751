import { Validation, IValidation } from './validation';

export class EqualTo extends Validation implements IValidation {
    toFieldName:string = ""
    
    isValid(field_name) {
      
        this.toFieldName = field_name;
        if (this.ngForm.value[field_name]){
            return this.value == this.ngForm.value[field_name];
        }else{
            return false;
        }
    }

    getMessage(): string {
        return `Please enter the same ${this.toFieldName} again.`;
    }
}