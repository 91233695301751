import { Validation, IValidation } from './validation';

export class Domain extends Validation implements IValidation {

    isValid() {
        return this.value.length > 0 &&
            this.value.length <= 253 && // Including sub domains
            !(/[^a-zA-Z0-9]/.test(this.value.slice(-2))) && !(/[^a-zA-Z0-9]/.test(this.value.substr(0, 1))) && !(/[^a-zA-Z0-9\.\-]/.test(this.value)) &&
            this.value.split('..').length === 1 &&
            this.value.split('.').length > 1;
    }

    getMessage(): string {
        return `Please enter valid Domain.`;
    }
}