import { Directive, ElementRef, Optional, Host, SkipSelf, Output, EventEmitter, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[appFieldInput]',
    host: {
        '[class.is-invalid]': "!isValid"
    }
})
export class FieldInputDirective {
    ngModelChange: EventEmitter < any > = new EventEmitter();

    constructor(
        @Optional() @Host() @SkipSelf()
        public ngModle: NgModel,
        public el: ElementRef
    ) {
    }

    private _value: any;

    public get value(): any {
        return this._value;
    }

    public set value(value: any) {
        switch (this.el.nativeElement.type) {
            case 'radio':
            case 'checkbox':
                this._value = value;
                if (this.el.nativeElement.value == value) {
                    this.el.nativeElement.checked = true;
                } else {
                    this.el.nativeElement.checked = false;
                }
                break;

           // case 'mat-radio-group':
               // this._value = value;
               // this.ngModle.value == value;
                //this.el.nativeElement.value == value;
                //break
            default:
                this._value = value;
                this.el.nativeElement.value = value;
                break;
        }
    }

    private _isValid: boolean = true;
    public get isValid(): boolean {
        return this._isValid;
    }

    public set isValid(value: boolean) {
        this._isValid = value;
    }

    @HostListener('chagne', ['$event'])
    @HostListener('input', ['$event'])
    onInputChange($event) {
        const value = $event.target.value;
        this.ngModelChange.emit(value)
    }

    
}