import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/services/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  // { path: 'ck-editor', loadChildren: () => import('./ck-editor/ck-editor.module').then(m => m.CkEditorModule) },
  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    paramsInheritanceStrategy: 'always',
   // useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
