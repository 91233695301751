import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepeaterComponent } from './repeater/repeater.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RepeaterFieldDirective } from './dirictives/repeater-field.directive';
import {  MatIconModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		DragDropModule
	],
	declarations: [
		RepeaterComponent,
		RepeaterFieldDirective
	],
	exports: [
		RepeaterComponent,
		RepeaterFieldDirective
	]
})
export class RepeaterFieldModule { }
