import { IValidation, Validation } from './validation';

export class Min extends Validation implements IValidation {
    attrs
    isValid(...attrs) {
        this.attrs = attrs;
        return this.value.length >= parseInt(this.attrs[0]);
    }

    getMessage(): string {
        return `Please enter at least ${this.attrs[0]} characters.`;
    }
}